import React from "react";
import WelcomeAdmin from "./pages/WelcomeAdmin";

export default function Admin() {
  return (
    <>
      <WelcomeAdmin />
    </>
  );
}
