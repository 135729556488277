import React from "react";

export default function Error404() {
  return (
    <div className="flex flex-col h-[100vh] bg-gray-100 justify-center items-center">
      <h1>Error!</h1>
      <h4>404, Page Not Found</h4>
    </div>
  );
}
